@import '../../../styles/settings.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.PaymentRadio {
  display: inline-flex;
  float: left;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  @include media-breakpoint-down(xs) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .PaymentRadio__label {
    position: relative;
    cursor: pointer;
    width: 100%;
    background: #fff;

    .PaymentRadio__round {
      .circle {
        &-outline {
          border: 1px solid #c1c1c1;
        }

        &-fill {
          background: #c1c1c1;
        }
      }
    }
    .PaymentRadio__round__selected {
      .circle {
        &-outline {
          border: 1px solid $primary;
        }

        &-fill {
          background: $primary;
        }
      }
    }
  }

  &-method {
    display: flex;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.05);
    height: 65px;
  }

  &__icon {
    font-size: rem(31);

    html[dir='ltr'] & {
      margin-right: 10px;
    }

    html[dir='rtl'] & {
      margin-left: 10px;
    }
  }

  &__round {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 58px;

    html[dir='ltr'] & {
      border-right: 1px solid $border-color;
    }

    html[dir='rtl'] & {
      border-left: 1px solid $border-color;
    }

    .circle {
      &-outline {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      &-fill {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-flex;
      }
    }
  }

  &__text {
    padding: 15px;
    padding: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $text-color;
  }
}
