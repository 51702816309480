@import '../../../styles/settings.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';


.PaymentPlanRadio {
  input[type='radio'] {
      display: none;

      & + .PaymentPlanRadio__label {
          position: relative;
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: rem(32) 10px;
          margin: 0;
          @include media-breakpoint-down(md) {
              padding: rem(30) 5px;
          }

          .PaymentPlanRadio__round {
              .circle {
                  &-fill {
                      background: #c1c1c1;
                  }
              }
          }
      }

      &:checked + .PaymentPlanRadio__label {
          .PaymentPlanRadio__round {
              .circle {
                  &-fill {
                      background: $primary;
                  }
              }
          }
      }
  }

  &__round {
      display: inline-flex;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;

      .circle {
          &-fill {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              .icon-tick {
                  color: #fff;
                  font-size: 9px;
                  font-weight: 700;
              }
          }
      }
  }

  &__text {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-right {
          font-size: rem(18);
          text-decoration: underline;
      }
  }

  &__maintext {
      font-size: 16px;
      color: $gray-900;
      line-height: normal;
      margin-bottom: 5px;
  }

  &__subtext {
      font-size: 14px;
      color: $text-muted;
      line-height: normal;

      &--primary {
          color: $primary;
      }
  }
}
