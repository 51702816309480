@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/grid';
@import 'bootstrap/scss/mixins/breakpoints';

@import '../../styles/settings';

.AccordionBlocks {
  html[dir='ltr'] & {
    padding-right: 35px;
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }
  html[dir='rtl'] & {
    padding-left: 35px;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }
  &__item {
    margin-bottom: 1.5rem;
    border-radius: 4px;
    background: #fafafa;
    &--border {
      border: 1px solid rgba(#c7cad1, 0.45);
    }
    &--boxshadow {
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.05);
    }
    &--curve {
      border-radius: 15px;
    }
    &--bg-white {
      background: $white;
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: rem(35);
    position: relative;
    @include media-breakpoint-down(md) {
      padding: rem(25) 15px;
      html[dir='ltr'] & {
        padding-right: 30px;
      }
      html[dir='rtl'] & {
        padding-left: 30px;
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      html[dir='ltr'] & {
        margin-right: rem(30);
        @include media-breakpoint-down(md) {
          margin-right: rem(15);
        }
      }
      html[dir='rtl'] & {
        margin-left: rem(30);
        @include media-breakpoint-down(md) {
          margin-left: rem(15);
        }
      }
    }
  }
  &__title {
    color: $primary;
    font-family: 'Montserrat';
    font-size: 18px !important;
    margin-bottom: 0;
    &--bold {
      font-weight: 500;
    }
  }

  &__collapse {
    display: none;
    &--in {
      display: block;
    }
  }
  &__actionText {
    font-size: 13px;
    color: $text-muted;
    line-height: normal;
    margin-top: 5px;
  }
  &__body {
    padding: rem(40);
    padding-top: 0;
    color: $gray-700;
    line-height: 23px;
    html[dir='ltr'] & {
      padding-left: 93px;
      @include media-breakpoint-down(md) {
        padding-left: 15px;
      }
    }
    html[dir='rtl'] & {
      padding-right: 93px;
      @include media-breakpoint-down(md) {
        padding-right: 15px;
      }
    }
    @include media-breakpoint-down(md) {
      padding: rem(30) 15px;
      padding-top: 0;
    }
  }
}

.tick {
  font-size: rem(18);
  color: #d0d0d0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  html[dir='ltr'] & {
    right: -35px;
    @include media-breakpoint-down(md) {
      right: 12px;
    }
  }
  html[dir='rtl'] & {
    left: -35px;
    @include media-breakpoint-down(md) {
      left: 12px;
    }
  }
  &.active {
    color: $primary;
  }
}

.summary {
  line-height: 19px;
  &__item {
    &-type {
      color: $gray-900;
      margin: 0 4px;
    }
  }
}
