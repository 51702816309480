@import '../../styles/settings';

.SlideBox {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(#000, 0.1);
  opacity: 0;
  visibility: hidden;
  z-index: 1000;

  h4 {
    font-weight: 400;
    margin-bottom: rem(22);
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .SlideBoxInner {
      html[dir='ltr'] & {
        right: 0;
        transition: right 0.4s ease-in-out;
      }

      html[dir='rtl'] & {
        left: 0;
        transition: left 0.4s ease-in-out;
      }
    }
  }

  &Inner {
    max-width: 605px;
    width: 100%;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    padding: rem(40);
    overflow: auto;

    html[dir='ltr'] & {
      right: -605px;
      transition: right 0.4s ease-in-out;
    }

    html[dir='rtl'] & {
      left: -605px;
      transition: left 0.4s ease-in-out;
    }

    @media (max-width: 575.98px) {
      padding: rem(30) 15px;
    }
  }
  &__close {
    margin-bottom: rem(33);
    color: $gray-900;
  }
}
