@import '../../styles/settings';

.CouponCard {
  display: block;

  &__item {
    display: flex;
    width: 100%;
    margin-bottom: rem(20);
  }

  &-inner {
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.05);
    background: #fff;
    border: 1px solid rgba(#c7cad1, 0.45);
    padding: rem(30);
    border-radius: 5px;
    width: 100%;

    @media (max-width: 767.99px) {
      padding: rem(20) rem(15);
    }
  }

  &__title {
    font-size: rem(17);
    font-weight: 500;
    margin: 15px 0 5px 0;
  }

  &__cnt {
    color: $text-muted;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: rem(20);
  }

  &__viewbtn {
    margin-bottom: rem(20);

    .Button {
      text-transform: capitalize;
    }
  }
}
