@import '../../../styles/settings.scss';

.Plan {
    display: flex;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;

    &-box {
        flex-basis: 0;
        flex-grow: 1;
        padding-left: 8px;
        padding-right: 8px;
        max-width: 305px;
        margin-bottom: rem(20);

        @media (max-width: 575.98px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &-inner {
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.05);
        background: #fff;
        border: 1px solid rgba(#c7cad1, 0.45);
        padding: rem(25) 15px;
        border-top: 6px solid $secondary;
        border-radius: 4px;
        width: 100%;
        height: 100%;
    }

    &__content {
        text-align: center;
    }

    &__type {
        color: $primary;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 10px 0 16px 0;
        display: inline-block;
        font-family: 'Montserrat';
    }

    &__price {
        font-size: rem(30) !important;
        color: $primary;
        font-weight: 400;
        margin: 0;
        line-height: normal;
    }

    &__originalamount {
        font-size: 16px;
        color: #8d8d8d;
        font-family: 'Montserrat';
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            height: 1px;
            background: #c6c6c6;
        }
    }

    &__discountinfo {
        background: rgba($primary, 0.1);
        font-family: 'Montserrat';
        color: $primary;
        font-size: 10px;
        font-weight: 700;
        margin-bottom: rem(13);
        margin-top: 5px;
        display: inline-block;
        padding: 5px 14px 3px 14px;
        border-radius: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &__priceperclass {
        font-size: rem(18);
        font-weight: 500;
        color: $gray-900;
        margin: 6px 0 rem(3) 0;
        display: block;
    }

    &__perclass {
        color: $text-muted;
        font-size: 13px;

        html[dir='ltr'] & {
            margin-left: 2px;
        }

        html[dir='rtl'] & {
            margin-right: 2px;
        }
    }

    &__note {
        font-size: 13px;
        color: $text-muted;
        margin-top: 0;
        margin-bottom: rem(25);
        line-height: 18px;

        sup {
            color: $primary;
            font-size: 15px;
            position: relative;
            top: 5px;
        }
    }

    &__btn {
        margin-bottom: rem(35);

        .Button {
            min-width: 186px;
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        width: 100%;
        position: relative;
        height: 200px;
        overflow: hidden;
        &.Expanded {
            height: auto;
            overflow: inherit;
            &:after {
                display: none;
            }
        }
        &:after {
            content: '';
            width: 100%;
            height: 52px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 1) 100%);
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 1) 100%);
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
        }

        &-item {
            position: relative;
            margin-bottom: rem(8);

            html[dir='ltr'] & {
                padding-left: 25px;
            }

            html[dir='rtl'] & {
                padding-right: 25px;
            }

            &:before {
                position: absolute;
                top: 2px;
                content: '\e929';
                font-family: 'icomoon' !important;
                color: $secondary;
                font-size: 13px;

                html[dir='ltr'] & {
                    left: 0;
                }

                html[dir='rtl'] & {
                    right: 0;
                }
            }

            &-label {
                color: $text-muted;
                font-size: 13px;
                display: block;
            }

            p {
                margin: 0;
                color: $gray-900;
                font-size: 13px;
            }
        }
    }

    &__readmore {
        margin-top: rem(10);
        html[dir='ltr'] & {
            padding-left: 25px;
        }

        html[dir='rtl'] & {
            padding-right: 25px;
        }
        .Button {
            font-size: 12px;
        }
    }
}
