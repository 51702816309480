@import '../../../styles/settings.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';


.PaymentPlan {
    &__body {
        padding: rem(30) 18px;
        padding-top: 0;

        html[dir='ltr'] & {
            padding-left: 55px;

            @include media-breakpoint-down(md) {
                padding-left: 15px;
            }
        }

        html[dir='rtl'] & {
            padding-right: 55px;

            @include media-breakpoint-down(md) {
                padding-right: 15px;
            }
        }
        @include media-breakpoint-down(md) {
            padding: rem(25) 15px;
            padding-top: 0;
        }
    }
}
