@import '../../styles/settings';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

footer {
    background: #f5f5f5;
    .footer-top {
        padding: rem(60) 0;
        .footerTxt {
            line-height: 24px;
        }
        h6 {
            margin-bottom: rem(38);
        }
        ul {
            li {
                margin-bottom: 10px;
                padding: 0;
                a {
                    color: $footer-text;
                    margin: 0;
                    padding: 0;
                    font-size: 15px;
                    transition: color 0.3s ease;
                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }

        .footer-menu-list {
            padding-left: 0;
            list-style: none;
            margin-left: -15px !important;
            margin-right: -15px !important;
            display: flex;
            flex-wrap: wrap;
            & > li {
                //width: 50%;
                padding: 0px 15px;
                p,
                span {
                    font-size: 16px;
                    color: $text-color;
                    text-transform: uppercase;
                    display: inline-block;
                    width: 100%;
                    position: relative;
                    margin-bottom: 20px;
                    font-weight: 500;
                }
                ul {
                    padding: 0;
                    list-style: none;
                    margin: 0;
                    li {
                        a {
                            img {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        
        .social-links {
            a {
                color: #afafaf;
                font-size: 22px;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
    .address {
        p{
            line-height: 28px;
        }
    }
    .copyright-blk {
        font-size: 14px;
        .copy-right {
            display: inline-block;
        }
    }
    .subscribe-block {
        input {
            border-width: 0;
            font-size: 15px;
        }
        .btn-subscribe {
            text-transform: capitalize;
            font-size: rem(18);
        }
        .message_validation {
            position: absolute;
            bottom: -25px;
            html[dir="ltr"] & {
                left: 16px;
            }
            html[dir="rtl"] & {
                right: 16px;
            }
        }
        .input-group > .custom-select:not(:last-child),
        .input-group > .form-control:not(:last-child) {
            html[dir="rtl"] & {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        .input-group > .input-group-append > .btn,
        .input-group > .input-group-append > .input-group-text,
        .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
        .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
        .input-group > .input-group-prepend:not(:first-child) > .btn,
        .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
            html[dir="rtl"] & {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .input-group-lg>.form-control {
            padding: 18px 15px;
            &::-webkit-input-placeholder {
                color: #7e7e7e;
            }
            &::-moz-placeholder {
                color: #7e7e7e;
            }
            &:-ms-input-placeholder {
                color: #7e7e7e;
            }
            &:-moz-placeholder {
                color: #7e7e7e;
            }
        }
    }
    .last-box {
        max-width: 426px;
    }
}
.footer-txt{
    color: $footer-text;
}
