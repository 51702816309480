@import '../../styles/settings';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

header {
  .drop {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px;
    z-index: 9;
    color: #8c8c8c;
    font-size: 13px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .header-top {
    padding: 10px 0;
    background-color: $white;
    .mr-80 {
      margin-right: 80px;
    }
    a{
      color: #0f1312;
    }
  }
  .header-block {
    border-radius: 0 0 20px 20px;
    @include media-breakpoint-down(sm) {
      background-color: transparent !important;
      padding: 0 !important;
    }
    .logo {
      @include media-breakpoint-down(sm) {
        background-color: $primary;
        padding: 15px 20px;
        border-radius: 0 0 20px 20px;
      }
    }
    .menu {
      font-size: 18px;
      min-width: 30px;
      text-align: center;
      i {
        color: $white;
      }
    }
    .search {
      font-size: 24px;
      min-width: 30px;
      text-align: center;
      i {
        color: $white;
      }
    }
    .navigation {
      .nav {
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
      }
      > ul {
        .cursor-pointer{
          font-weight: 500;
        }
        > li {
          @include media-breakpoint-up(lg) {
            margin-left: 80px;
          }
          margin-left: 30px;
          color: $white;
          position: relative;

          &:hover {
            .dropdown {
              margin-top: 0;
              visibility: visible;
              opacity: 1;
            }
          }
          > a {
            color: $white;
            font-weight: 500;
          }
          .dropdown {
            position: absolute;
            list-style: none;
            margin: 0;
            padding: 0;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 0 0 10px 10px;
            top: 0;
            left: 50%;
            transform: translate(-50%, 25px);
            min-width: 160px;
            margin-top: 15px;
            visibility: hidden;
            opacity: 0;
            transition: 0.4s;
            border: 1px solid $border-color;
            @include media-breakpoint-down(sm) {
              visibility: visible;
              opacity: 1;
              position: relative;
              transform: translateY(10px);
              left: 0;
              margin-top: 0;
              border-radius: 0;
            }
            li {
              font-size: 12px;
              padding: 10px 0;
              border-bottom: 1px solid #bfbdba;
              margin: 5px 15px;
              //    &:hover{
              //        background-color: #fff;
              //    }
              a {
                color: #757575;
                font-weight: 600;
              }
              &:last-child {
                border: none;
              }
            }
            &.desktop {
              @media only screen and (max-width: 768px) {
                display: none !important;
              }
            }
            &.mobile {
              display: none;
              @media only screen and (max-width: 768px) {
                display: block !important;
              }
            }
            .arrow-icon {
              display: none;
              @media only screen and (max-width: 768px) {
                display: block !important;
              }
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        overflow: auto;
        transform: translateX(-100%);
        transition: 0.4s;
        &.expand {
          transform: translateX(0%);
        }
        .menu-close {
          position: absolute;
          right: 0;
          top: 0;
          padding: 13px;
          margin: 5px 10px;
          i {
            font-size: 16px;
            color: #8e8e8e;
          }
        }
        .nav {
          display: block;
          li {
            margin-left: 0;
            color: $dark;
            font-size: 14px;
            padding: 10px 0px;
            a {
              color: $dark;
            }
          }
        }
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #f4f4f4;
        min-width: 280px;
        max-width: 280px;
        padding: 45px 20px 15px 20px;
      }
      .inner-nav {
        .call {
          justify-content: space-between;
          padding: 10px 15px;
          border-radius: 0 0 8px 8px;
          span {
            font-size: 16px;
            color: #fff;
          }
          .icn {
            padding-left: 15px;
            border-left: 1px solid #f0f0f0;
            display: flex;
            align-items: center;
          }
        }
        .mob-app {
          border-top: 1px solid #d3d3d3;
        }
        .before-gray {
          &::before {
            color: #8c8c8c !important;
          }
        }
        @include media-breakpoint-down(md) {
          a, .download-app{
            color: #0f1312;
            font-weight: $font-weight-normal;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

.zIndex-9{
  z-index: 9;
}

.logo-header{
  @include media-breakpoint-down(md) {
    max-width: 265px;
  } 
  @include media-breakpoint-down(xs) {
    max-width: 155px;
  } 
}

.gutter-8 {
  margin-left: -8px!important;
  margin-right: -8px!important;
  &>[class^=col] {
    padding-left: 8px!important;
    padding-right: 8px!important;
  }
}