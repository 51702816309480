@import '../../../styles/settings.scss';

.ApplyCoupon {
  border: 1px dashed $border-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 14px;
  min-height: 55px;
  background: #fff;
  text-transform: uppercase;

  &__content {
    display: flex;
    align-items: center;

    html[dir='ltr'] & {
      margin-right: 10px;
    }

    html[dir='rtl'] & {
      margin-left: 10px;
    }
  }

  &__title {
    color: $text-color;
    font-size: 14px;
    font-weight: 500;
  }

  &__subtitle {
    color: $text-muted;
    font-size: 13px;
    display: block;
  }

  &__icon {
    font-size: rem(21);
    display: inline-flex;

    html[dir='ltr'] & {
      margin-right: 10px;
    }

    html[dir='rtl'] & {
      margin-left: 10px;
    }
  }

  &__btn {
    .Button {
      color: $gray-900;
    }
  }
}
