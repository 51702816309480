@import '../../styles/settings';

.Input {
  &--sticky {
    position: absolute;
  }
  position: relative;
  font-size: 1rem;
  &--gap-lg {
    margin-bottom: rem(32);
  }
  &--gap-md {
    margin-bottom: rem(25);
  }
  &--gap-sm {
    margin-bottom: 10px;
  }
  &__label {
    display: inline-block;
    margin-bottom: 8px;
    color: $gray-900;
    font-size: 13px;
    line-height: normal;
    width: 100%;
    &--full {
      width: 100%;
    }
    &--font-weight {
      &-light {
        font-weight: 300;
      }
      &-normal {
        font-weight: 400;
      }
      &-medium {
        font-weight: 500;
      }
      &-bold {
        font-weight: 700;
      }
    }
    &--size-sm {
      font-size: rem(14) !important;
    }
    &--size-md {
      font-size: rem(16) !important;
    }
    &--size-lg {
      font-size: rem(18) !important;
    }
    &--light {
      color: $gray-400 !important;
    }
    &--medium {
      color: $gray-700 !important;
    }
    &--dark {
      color: $gray-900 !important;
    }
    &--gap-lg {
      margin-bottom: rem(30) !important;
    }
    &--gap-md {
      margin-bottom: 1.5rem !important;
    }
    &--gap-sm {
      margin-bottom: 0.625rem !important;
    }
  }
  &__field {
    display: block;
    width: 100%;
    padding: rem(16) rem(13);
    font-size: rem(15);
    line-height: normal;
    color: $gray-900;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $border-color;
    border-radius: 4px;
    outline: none;
    font-family: inherit;
    height: 53px;
    &::-webkit-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &::-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-ms-input-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:-moz-placeholder {
      color: $gray-400;
      font-family: inherit;
    }
    &:focus {
      outline: none;
    }
    &--lg {
      font-size: 2.0625em !important;
      font-weight: 500 !important;
      color: $gray-900 !important;
    }
    &--sm {
      padding: 0.75rem;
      font-size: 0.9375em !important;
    }
    &--border-sm {
      border-width: 1px;
    }
    &--noborder {
      border: none !important;
    }
    &--icon {
      background-color: transparent;
      z-index: 1;
      position: relative;
      html[dir='ltr'] & {
        padding-right: 40px;
      }
      html[dir='rtl'] & {
        padding-left: 40px;
      }
      + i {
        position: absolute;
        bottom: 17px;
        font-size: 1.25em;
        color: $gray-400;
        z-index: 0;
        html[dir='ltr'] & {
          right: 15px;
        }
        html[dir='rtl'] & {
          left: 15px;
        }
        &.icon {
          &-sm {
            font-size: 0.8em;
          }
          &-xs {
            font-size: 0.5em;
            bottom: 17px;
          }
        }
        &.icon--primary {
          color: $primary;
        }
      }
      + .InputLoading {
        position: absolute;
        bottom: 24px;
        z-index: 0;
        html[dir='ltr'] & {
          right: 15px;
        }
        html[dir='rtl'] & {
          left: 15px;
        }
      }
    }
    &--disabled{
      background: #f2f2f2;
    }
  }
  &::placeholder {
    color: $gray-400;
    font-family: inherit;
  }
  &__error {
    display: block;
    color: red;
    font-size: 0.875rem;
  }
  &__mobile {
    position: relative;
    z-index: 0;
    margin-left: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0;
    html[dir='ltr'] & {
      padding-right: 6px;
      padding-left: 117px;
    }
    html[dir='rtl'] & {
      padding-right: 117px;
      padding-left: 6px;
    }
  }
  &Loading {
    color: hsl(0, 0%, 80%);
    display: flex;
    transition: color 150ms;
    align-self: center;
    font-size: 4px;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    &__dot {
      background-color: currentColor;
      border-radius: 1em;
      display: inline-block;
      height: 1em;
      vertical-align: top;
      width: 1em;
      html[dir='ltr'] & {
        margin-left: 4px;
      }
      html[dir='rtl'] & {
        margin-right: 4px;
      }
      &F {
        -webkit-animation: animation-stj4i2 1s ease-in-out 0ms infinite;
        animation: animation-stj4i2 1s ease-in-out 0ms infinite;
      }
      &M {
        -webkit-animation: animation-stj4i2 1s ease-in-out 200ms infinite;
        animation: animation-stj4i2 1s ease-in-out 200ms infinite;
      }
      &L {
        -webkit-animation: animation-stj4i2 1s ease-in-out 360ms infinite;
        animation: animation-stj4i2 1s ease-in-out 360ms infinite;
      }
    }
  }
}
@keyframes animation-stj4i2 {
  0%,
  80%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
.basic-single {
  .select {
    &__control {
      border-color: $border-color !important;
      box-shadow: none !important;
      &:hover {
        border-color: $border-color !important;
      }
      .select__input {
        input {
          padding: 0 !important;
        }
      }
    }
    &__menu {
      .select__option {
        &.select__option--is-focused {
          background-color: rgba($primary, 0.1);
        }
      }
    }
  }
}
