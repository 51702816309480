@import '../../styles/settings';

.Verification {
  &__content {
    margin-bottom: rem(30);
  }
  &__heading {
    margin-bottom: rem(8);
  }
  &__text {
    color: $text-muted;
    margin: 0;
    font-size: 14px;
  }
  &__number {
    color: $gray-900;
  }
  .Button__link {
    text-transform: capitalize;
    text-decoration: underline;
  }
}

.RegistrationForm {
  max-width: 1092px;
  margin: 0 auto;
}
