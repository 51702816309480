.personalaInformation {
  .active-plan {
    color: #fff;
  }
  .active-plan .planlabel {
    color: #ffff !important;
  }
  .planlabel {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .active-plan .custom-radio [type='radio']:checked + label:before {
    background-color: #fff;
  }

  .active-plan .custom-radio [type='radio']:checked + label:after {
    top: 4px;
    left: 3px;
    width: 12px;
    height: 12px;
    border: 2px solid #161616;
  }
  html[dir='rtl']
    &
    .active-plan
    .custom-radio
    [type='radio']:checked
    + label:after {
    right: 3px;
  }

  .active-plan .points svg circle {
    fill: #fff;
  }
  .active-plan .points svg path {
    fill: #000;
  }
  .planlabel {
    color: #151817 !important;
  }
  .paymentlabel {
    padding-left: 60px !important;
  }
  html[dir='rtl'] & .paymentlabel {
    padding-right: 60px !important;
    padding-left: 25px !important;
  }

  html[dir='rtl'] & .paymentlabel::before {
    right: 25px !important;
  }
  html[dir='rtl'] .paymentlabel::after {
    right: 32px !important;
  }
  .paymentlabel::before {
    left: 25px !important;
    top: 25px !important;
    height: 21px !important;
    width: 21px !important;
    border: 1px solid #e4e4e4 !important;
    background-color: #fff;
  }
  .paymentlabel::after {
    left: 32px !important;
    top: 32px !important;
    background: transparent !important;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-radius: 0 !important;
    transform: rotate(-45deg);
    width: 7px !important;
    height: 5px !important;
  }
  .payment [type='radio']:checked + label:before {
    background-color: #fff;
  }
  .payment [type='radio']:checked + label:after {
    border-color: #151817;
  }
  .payment [type='radio']:checked + label {
    background-color: #151817;
  }
  .payment [type='radio']:checked + label {
    color: #fff !important;
  }
  .payment [type='radio']:checked + label h4 {
    color: #fff !important;
  }
  .payment [type='radio']:checked + label p {
    color: #fff !important;
  }
  .payment [type='radio']:checked + label span {
    color: #fff !important;
  }

  .mode {
    html[dir='ltr'] & {
      padding-left: 60px !important;
    }
    html[dir='rtl'] & {
      padding-right: 60px !important;
    }
  }

  .payment-method [type='radio'] + label:before {
    html[dir='ltr'] & {
      left: 30px !important;
      top: 25px;
    }
    html[dir='rtl'] & {
      right: 30px !important;
      top: 25px;
    }
  }
  .payment-method [type='radio'] + label::after {
    html[dir='ltr'] & {
      left: 36px !important;
      top: 31px;
    }
    html[dir='rtl'] & {
      right: 36px !important;
      top: 31px;
    }
  }
}
