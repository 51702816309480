/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 21, 24, 23; /* #151817 */
    --secondary: 2, 49, 51; /* #023133 */
    --muted: 138, 140, 139; /* #8A8C8B */
    --slate-50: 248, 248, 248; /* #F8F8F8 */
    --slate-200: 228, 228, 228; /* #E4E4E4 */
    --slate-300: 231, 231, 231; /* #E7E7E7 */
    --default: 21, 24, 23; /* #151817  used for body font color */
  }
  .teal {
    --rmdp-primary-teal: #151817;
    --rmdp-today-teal: #151817;
    --rmdp-hover-teal: #151817;
  }
  @font-face {
    font-family: 'ITC Avant Garde Std';
    src: url('../assets/fonts/ITCAvantGardeStd-Bk.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Bk.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'ITC Avant Garde Std';
    src: url('../assets/fonts/ITCAvantGardeStd-Demi.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'ITC Avant Garde Std';
    src: url('../assets/fonts/ITCAvantGardeStd-Bold.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'ITC Avant Garde Std';
    src: url('../assets/fonts/ITCAvantGardeStd-Md.woff2') format('woff2'),
      url('../assets/fonts/ITCAvantGardeStd-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  body {
    @apply bg-slate-50;
  }
}

@layer components {
  .btn {
    @apply font-semibold text-[15px] px-[26px] pt-3 pb-2 focus:outline-none whitespace-nowrap inline-block text-center transition-all leading-[23px] rounded-md;
  }
  .btn-primary {
    @apply border border-primary bg-primary text-white hover:bg-black hover:border-primary;
  }
  .btn-outline-primary {
    @apply border border-primary bg-white text-primary hover:border-primary hover:bg-primary hover:text-white;
  }
  .btn-disabled {
    @apply border-[#808080] bg-[#808080] text-white pointer-events-none;
  }
  .form-control {
    @apply bg-white font-medium border border-[#B7B7B7] text-primary text-sm block w-full px-5 pt-3 pb-2 leading-[23px] focus:border-primary focus:outline-none placeholder-[#B7B7B7] rounded;
  }
  .form-control:disabled,
  .form-control.disabled {
    @apply bg-[#EAEAEA];
  }
  .dashboard-header,
  .dashboardInnerItem {
    @apply hidden !important;
  }
  @media (min-width: 1024px) {
    .dashboardLayout .dashboard-header {
      @apply block !important;
    }
    .dashboardLayout .header-bottom {
      @apply hidden;
    }
    strong {
      @apply font-bold;
    }
  }
  @media (max-width: 1023.98px) {
    .dashboardLayout {
      @apply pt-[97px];
    }

    .dashboardInnerLayout .logo {
      @apply hidden !important;
    }
    .dashboardInnerLayout .dashboardInnerItem {
      @apply flex !important;
    }
  }
  .container {
    max-width: 1184px !important; /* 1152 + 32 */
  }
  html[dir='rtl'] {
    text-align: right;
  }
}
.custom-checkbox,
.custom-radio {
  position: relative;
}
.custom-checkbox label {
  font-size: 14px;
  font-weight: 300;
}
[type='checkbox'],
[type='radio'] {
  display: none;
}

html[dir='ltr'] .custom-checkbox label {
  padding-left: 30px;
}
html[dir='rtl'] .custom-checkbox label {
  padding-right: 30px;
}
.custom-checkbox [type='checkbox'] + label:before {
  content: '';
  position: absolute;
  height: 18px;
  width: 18px;
  border-width: 1px;
  border-color: #161616;
}
html[dir='ltr'] .custom-checkbox [type='checkbox'] + label:before {
  left: 0px;
}
html[dir='rtl'] .custom-checkbox [type='checkbox'] + label:before {
  right: 0;
}
.custom-checkbox [type='checkbox']:checked + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 5px;
  border-left: #161616 2px solid;
  border-bottom: #161616 2px solid;
  width: 9px;
  height: 5px;
  transform: rotate(-50deg);
  opacity: 0;
}
.custom-checkbox [type='checkbox']:checked + label:after {
  opacity: 1;
}
html[dir='rtl'] .custom-checkbox [type='checkbox']:checked + label:after {
  right: 6px;
  left: inherit;
}

.custom-radio label {
  font-size: 14px;
  font-weight: 300;
  color: #8a8c8b;
  cursor: pointer;
}
html[dir='ltr'] .custom-radio label {
  padding-left: 30px;
}
html[dir='rtl'] .custom-radio label {
  padding-right: 30px;
}
.custom-radio [type='radio'] + label:before {
  content: '';
  position: absolute;
  height: 18px;
  width: 18px;
  border-width: 1px;
  border-color: #161616;
  border-radius: 50%;
  top: 1px;
}
.custom-radio [type='radio']:checked + label:before {
  background-color: #161616;
}
html[dir='ltr'] .custom-radio [type='radio'] + label:before {
  left: 0px;
}
html[dir='rtl'] .custom-radio [type='radio'] + label:before {
  right: 0;
}
.custom-radio [type='radio']:checked + label:after {
  content: '';
  position: absolute;
  top: 7px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
}
.custom-radio [type='radio']:checked + label:after {
  opacity: 1;
}

html[dir='rtl'] .custom-radio [type='radio']:checked + label:after {
  right: 6px;
  left: inherit;
}
.custom-radio [type='radio']:checked + label {
  color: #161616;
}

/* login */
.custom-select .select__control {
  border: none;
  padding: 0;
  min-height: 19px;
  height: 19px;
  outline: none;
}
.custom-select .select__value-container {
  padding: 0;
  line-height: 19px;
  outline: none;
}
.custom-select .select__indicators,
.select__indicator {
  padding: 0;
}
.custom-select .select__dropdown-indicator {
  padding: 0;
}
.custom-select .select__indicator-separator {
  display: none;
}
.custom-select .select__dropdown-indicator svg {
  display: none;
}
.custom-select .select__placeholder {
  color: #151817;
}
.custom-select .select__dropdown-indicator::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #151817;
  border-left: 1px solid #151817;
  transform: translate(-35%, -50%) rotate(-45deg);
}
.select__control:hover {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

/* register */
.form-control-select .select__control {
  border: 1px solid #b7b7b7 !important;
  min-height: 45px;
  padding: 0px 8px;
  box-shadow: none;
  font-size: 14px;
}
.form-control-select .select__control:hover {
  border: 1px solid #b7b7b7 !important;
}
.form-control-select .select__indicator-separator {
  display: none;
}
.form-control-select .select__control:focus {
  border: 1px solid #151817 !important;
  box-shadow: none;
}
.form-control-select .select__placeholder {
  font-size: 14px;
  color: #b7b7b7;
}
.form-control-select .select__indicator {
  color: #6f7171;
}
.phone-select .select__control {
  border: none;
  min-height: 20px;
}
.phone-select .select__indicator-separator {
  display: none;
}
.phone-select .select__indicator {
  padding: 0;
}
.phone-select .form-control {
  border: none;
}
.phone-select .select__value-container {
  line-height: 15px;
  padding: 0px 8px;
}
.phone-select .select__control {
  border: none !important;
}
.phone-select .select__indicator {
  color: #6f7171;
}

.select__control {
  outline: none !important;
  box-shadow: none !important;
}

body {
  color: rgba(var(--default), 1);
}

.custom-placeholder .select__placeholder {
  color: #b7b7b7;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.body-overflow-hidden {
  overflow: hidden;
}

html[dir='ltr'] .body-overflow-hidden {
  padding-right: 17px;
}

html[dir='rtl'] .body-overflow-hidden {
  padding-left: 17px;
}

@media (max-width: 1023.98px) {
  .newsdetail .logo {
    display: none;
  }
}
.select__option.select__option--is-focused,
.select__option:active {
  @apply bg-primary/10 !important;
}

.select__option.select__option--is-selected {
  @apply bg-primary text-white !important;
}

.faq-list ul {
  list-style: disc;
  padding-left: 18px;
}
.faq-list ul li {
  padding: 5px 0px;
}
.faq-list p {
  padding-bottom: 10px;
}

.select__menu-list {
  @apply text-sm;
}
.react-datepicker-wrapper input {
  background-image: url('/images/calendar.svg');
  background-repeat: no-repeat;
}
html[dir='ltr'] .react-datepicker-wrapper input {
  background-position: right 15px center;
  padding-right: 42px;
}
html[dir='rtl'] .react-datepicker-wrapper input {
  background-position: left 15px center;
  padding-left: 42px;
}

.tab-underline a {
  @apply after:scale-0 text-muted cursor-pointer lg:font-medium font-semibold lg:pb-3 pb-2 w-full text-base text-center block relative after:absolute after:border-primary after:border-b-2 after:block after:h-0.5 after:-bottom-px after:left-0 after:right-0 after:transition after:duration-500  transition-all;
}
.tab-underline .active a {
  @apply border-primary after:scale-100 text-primary;
}
.basis-0 {
  flex-basis: 0;
}

.toastr {
  @apply fixed z-50 top-4 bg-white left-0 right-0 mx-auto lg:max-w-[500px] max-w-[95%] rounded-md overflow-hidden shadow px-4 py-2;
}
.toastr > div {
  @apply flex items-center w-full;
}
.toastr .rrt-middle-container {
  @apply grid grid-flow-col gap-2 text-sm;
}
.toastr .rrt-left-container {
  @apply ltr:mr-4 rtl:ml-4;
}
.toastr .rrt-left-container svg {
  @apply !w-[20px] fill-current;
}
.toastr .rrt-right-container {
  @apply ltr:ml-auto rtl:mr-auto ltr:pl-3 rtl:pr-3;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #registration-payment .container .row .col.span-md-12 {
    flex: 0 0 100% !important;
  }
}
.accent {
  color: #f9b514;
}
.closed {
  color: #00594f;
}
.danger {
  color: #ee3236;
}
.trim__text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 639.98px) {
  #mobNotify + .Popup {
    max-width: 230px;
  }
}

.react-datepicker .react-datepicker__header {
  @apply bg-white;
}

.react-datepicker .react-datepicker__day {
  @apply rounded-full;
}

.react-datepicker .react-datepicker__day:hover {
  @apply bg-primary/10;
}

.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--keyboard-selected {
  @apply bg-primary text-white;
}

.react-datepicker .react-datepicker__navigation--previous {
  @apply border-r-primary;
}

.react-datepicker .react-datepicker__navigation--next {
  @apply border-l-primary;
}

.react-datepicker
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle {
  @apply border-b-white;
}

.DocumentBlock .table-thead,
.DocumentBlock .tbody-tr {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.disable-number-scrolls::-webkit-inner-spin-button,
.disable-number-scrolls::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.disable-number-scrolls {
  -moz-appearance: textfield;
}
