.Verification {
  .Input__label {
    html[dir='ltr'] & {
      text-align: left;
    }
    html[dir='rtl'] & {
      text-align: right;
    }
  }
}
