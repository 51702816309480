@import '../../../styles/settings';

.OTPInput {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1rem;
  margin: 1.75rem auto;
  @media only screen and (max-width: 991.98px) {
    justify-content: center;
  }
  &__field {
    display: block;
    max-width: 70px;
    width: 100%;
    padding: 14px 5px;
    font-size: rem(38);
    line-height: rem(48);
    color: #262626;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $border-color;
    outline: none;
    font-family: inherit;
    text-align: center;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    &:focus {
      outline: none;
    }
    &.disable-number-scrolls {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    @media (max-width: 450px) {
      font-size: rem(30);
    }
    @media (max-width: 350px) {
      font-size: rem(20);
    }
    @media (max-width: 282px) {
      font-size: rem(15);
    }
  }

  &__separator {
    width: 16px;
    height: 2px;
    background-color: $primary;
    display: inline-block;
    margin: 0 10px;
    @media only screen and (max-width: 767.98px) {
      margin: 0 0.75rem;
    }
  }
  .OTPInput__wrapper {
    &:not(:first-child) {
      html[dir='ltr'] & {
        margin-left: -1px;
      }
      html[dir='rtl'] & {
        margin-right: -1px;
      }
    }
    &:first-child,
    &:nth-child(5) {
      .OTPInput__field {
        html[dir='ltr'] & {
          border-radius: 4px 0 0 4px;
        }
        html[dir='rtl'] & {
          border-radius: 0 4px 4px 0;
        }
      }
    }
    &:last-child,
    &:nth-child(3) {
      .OTPInput__field {
        html[dir='ltr'] & {
          border-radius: 0 4px 4px 0;
        }
        html[dir='rtl'] & {
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
}
.disable-number-scrolls {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}