@import '../../../styles/settings.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';


.ChoosePaymentPlan {
    max-width: 535px;
    width: 100%;
}
.PaymentPlan {
    &__option {
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.05);
        background: #fff;
        border: 1px solid rgba(199, 202, 209, 0.45);
        border-radius: 4px;
        width: 100%;
        margin-bottom: rem(20);
    }

    &__price {
        font-size: rem(18);
        text-decoration: underline;
        position: relative;
        color: $text-color;
        html[dir='ltr'] & {
            text-align:right;
        }
        html[dir='rtl'] & {
            text-align:left;
        }
    }

    &__tooltip {
        position: absolute;
        bottom: -6px;
        border: 1px solid $border-color;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.02);
        font-size: 13px;
        color: $text-muted;
        text-decoration: none;
        transform: translateY(100%);
        background: #fff;
        line-height: 24px;
        padding: 15px;
        border-radius: 4px;
        z-index: 1;
        display: none;
        
        &.Show {
            display: block;
        }

        html[dir='ltr'] & {
            right: 0;
        }

        html[dir='rtl'] & {
            left: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: -6px;
            width: 9px;
            height: 9px;
            background: #fff;
            border-left: 1px solid $border-color;
            border-top: 1px solid $border-color;
            transform: rotate(45deg);

            html[dir='ltr'] & {
                right: 15px;
            }

            html[dir='rtl'] & {
                left: 15px;
            }
        }

        &-item {
            display: flex;
            white-space: nowrap;
        }

        &-price {
            color: $gray-900;
        }
    }
}