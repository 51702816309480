@import '../../../styles/settings';

.InputGroup {
  display: flex;
  .Input {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-bottom: 0 !important;
    &:last-child {
      .Input__field {
        html[dir='ltr'] & {
          border-left: none;
          border-radius: 0 4px 4px 0;
        }
        html[dir='rtl'] & {
          border-right: none;
          border-radius: 4px 0 0 4px;
        }
      }
    }
    &__field {
      &.disable-number-scrolls {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
.PhoneInput .select__control{
  border-right: 0px!important;
  border-radius: 4px 0 0 4px;
}

