@import '../../styles/settings';

.ApplyPromoCode {
  position: relative;
  margin-bottom: rem(50);
  .Input {
    &__label {
      font-size: rem(17);
    }
    &__field {
      html[dir='ltr'] & {
        padding-right: rem(110);
      }
      html[dir='rtl'] & {
        padding-left: rem(110);
      }
    }
  }
  .Button {
    position: absolute;
    bottom: 0;
    border-radius: 0;
    padding: rem(17) rem(29);
    html[dir='ltr'] & {
      right: 0;
    }
    html[dir='rtl'] & {
      left: 0;
    }
  }
}
