.PaymentMethod {
    height:800px;
    &__left,
    &__btn {
        max-width: 533px;
    }
    .Input__field {
        font-size: 14px;
    }
}

.PaymentMethod__btn {
    text-align: right;
}